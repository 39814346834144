@import "../src/assets/css/variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  //font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

html {
  font-size: 16px;
}

a {
  text-decoration: none;
}

html,
body {
  height: 100%;
  // overflow: hidden;
}

body {
  font-family: "DM Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  background: #d3d6e0;
  color: #020812;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

td,
th {
  vertical-align: middle;
}

tr {
  cursor: pointer;
}

tbody tr:hover {
  background: rgba(105, 164, 255, 0.07);
  // color: $primary;
}

td.mat-cell {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}

th.mat-header-cell {
  font-weight: bold;
}

table {
  width: 100%;
}

.mat-elevation-z8 {
  box-shadow: none;
  box-shadow: 3px 5px 19px rgba(209, 209, 209, 0.4);

  @media (max-width: 766px) {
    overflow: scroll;
    table {
      width: 200%;
    }
  }
}

.mat-cell {
  color: inherit;
}

.mat-sort-header-button,
.mat-sort-header-content {
  text-transform: uppercase;
  color: rgb(87, 87, 87) !important;
}

.page-title {
  font-weight: 500;
  font-size: 1.4rem;
  position: relative;
  top: 14px;
  letter-spacing: -0.3px;
  color: #192879;

  &.static {
    position: static;
  }

  @media (max-width: 766px) {
    position: static;
    padding: 2rem 0 2rem;
  }
}

.page-subtitle {
  font-weight: 500;
  letter-spacing: -0.5px;
  font-size: 1.25rem;
  padding-bottom: 1rem;
  color: rgba(0, 0, 0, 0.8);
  // color: $primary;

  &.huge {
    font-size: 1.675rem;
    padding-bottom: 2rem;
    color: rgba(0, 14, 78, 0.8);
  }
}

table a {
  color: $primary;
  // color: inherit;
}

.page-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;

  @media (max-width: 766px) {
    display: block;
  }
}

.page-actions {
  position: relative;
  top: 20px;
  display: flex;
  width: 100%;

  .page-action {
    margin-left: 6px;
    //width: percentage(1/5);
  }

  @media (max-width: 766px) {
    display: block;
    position: static;

    .page-action {
      margin-left: 0;
      width: 100%;
    }

    .mdc-text-field {
      width: 100%;
    }
  }
}

.request-actions,
.page-actions {
  .mat-icon {
    margin-left: 6px;
    position: relative;
    top: 4px;
  }

  .material-icons {
    font-size: 16px;
  }

  .mdc-button {
    line-height: 38px;
  }
}

table tr.mat-row,
table tr.mat-footer-row {
  height: 59px;
}

.status-group {
  padding: 1rem 0 0.25rem;

  .status-label {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.status-label {
  display: inline-block;
  position: relative;
  padding: 4px 8px;
  border-radius: 100px;
  letter-spacing: 0!important;
  font-size: 12px;
  text-transform: inherit;

  &:before {
    content: "";
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 100%;
    margin-right: 6px;
    position: relative;
    top: -2px;
  }

  &.open,
  &.inactive,
  &.incomplete,
  &.rejected,
  &.disabled,
  &.closed,
  &.rejectSubmitted,
  &.todo,
  &.failed,
  &.unprocessed,
  &.not-started,
  &.not-funded,
  &.has-no-target,
  &.has-no-autosave,
  &.not_funded,
  &.not-set,
  &.not_matching,
  &.canceled,
  &.is-locked,
  &.withdrawal {
    &:before {
      background-color: #ff443d;
    }
  }



  &.started {
    background: #e5f0f6;

    &:before {
      background: #20A4F3;
    }
  }

  &.in-progress {
    background: #e5f0f6;

    &:before {
      background: #000000;
    }
  }

  &.submitted,
  &.pending,
  &.no-name {
    background: #fefed6;

    &:before {
      background: #e3d512;
    }
  }

  &.cancelled,
  &.not-completed {
    background: #f1f1f1;
  }

  &.inactive,
  &.not-started,
  &.not-funded,
  &.not_funded,
  &.incomplete,
  &.rejected,
  &.disabled,
  &.todo,
  &.failed,
  &.unprocessed,
  &.not_matching,
  &.is-locked,
  &.has-no-target,
  &.has-no-autosave,
  &.danger,
  &.withdrawal {
    background: #fff2f2;
  }

  &.active,
  &.complete,
  &.is-representative,
  &.external,
  &.funded,
  &.approved,
  &.successful,
  &.processed,
  &.verified,
  &.matching,
  &.is-not-locked,
  &.has-target,
  &.has-autosave,
  &.deposit {
    background: #e7f9f0;
  }

  &.not-set {
    background: #fdd29c;
  }

  &.cancelled{
    &::before {
      background-color: #5A5A5A;
    }
  }

  &.processing,
  &.not-set{
    &::before {
      background-color: rgb(255, 168, 37);
    }
  }

  &.active,
  &.is-representative,
  &.external,
  &.complete,
  &.verified,
  &.funded,
  &.approved,
  &.successful,
  &.processed,
  &.matching,
  &.is-not-locked,
  &.has-target,
  &.has-autosave,
  &.deposit {
    &:before {
      background-color: #83cc56;
    }
  }
}

.right-flow{
  text-align: right !important;
}
.network-verification{
  font-size: 12px
}

.mo-label{
  display: inline-block;
  position: relative;
  padding: 4px 20px;
  border-radius: 100px;
  letter-spacing: 0!important;
  font-size: 12px;
  text-transform: inherit;
  &.m0b{
    background: #FFA29A;
  }
  &.m0a{
    background: #FFCEC9;
  }
  &.m1{
    background: #FADBD8;
  }
  &.m2{
    background: #FAD5A5;
  }
  &.m3{
    background: #FFE4B5;
  }
  &.m4{
    background: #FFEFD5;
  }
  &.m5{
    background: #FFF8DC;
  }
  &.m6{
    background: #DBFCF5;
  }
  &.m7{
    background: #C3FFF2;
  }
  &.m8{
    background: #90FFE7;
  }

}

.u-txt-green {
  color: #67a342;
}

.u-txt-red {
  color: #f24a44;
}

.mat-flat-button.mat-accent {
  background-color: #39af88;
}

.mdc-form-field-appearance-fill .mdc-form-field-flex {
  background-color: #fff;
}

.mdc-form-field-label {
  color: $primary;
}



.xn-summary {
  background: #fff;
  display: flex;

  > div {
    flex: 1;
    padding: 0.75rem 1.5rem;
    border-right: solid 1px #e7e7e7;

    &:last-child {
      border-right: none;
    }
  }

  .status {
    span {
      display: inline-block;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 0.7rem;
    padding-bottom: 0.625rem;
    text-transform: uppercase;
    letter-spacing: -0.3px;
  }

  span {
    display: block;
    font-size: 14px;
  }
}

.xn-table {
  font-size: 0.875rem;
  background-color: #fff;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  width: 100%;
  text-align: left;
  // table-layout: fixed;
  line-height: 1;
  border-collapse: collapse;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  &.is-fixed {
    table-layout: fixed;
  }

  a {
    cursor: pointer;
  }

  &.is-summary {
    table-layout: auto;

    td:first-child {
      background-color: rgba(0, 0, 0, 0.03);
      color: rgba(0, 0, 0, 0.9);
      width: 50%;
    }

    td:last-child {
      //font-weight: 400;
      width: 50%;
    }

    td {
      border-bottom: solid 1px rgba(0, 0, 0, 0.02);
    }

    tr {
      border-bottom: none;
    }

    th,
    td {
      height: 44px;
    }

    tr:hover {
      background: transparent;
      cursor: auto;
    }
  }

  &.is-highlighted {
    td:first-child {
      background-color: rgba(126, 245, 162, 0.1);
    }

    td:last-child {
      background-color: rgba(126, 245, 162, 0.03);
      font-weight: bold;
    }
  }

  thead {
    th {
      border-bottom: solid 1px rgba(0, 0, 0, 0.06);
      font-size: 0.7rem;
      border-top: solid 1px rgba(0, 0, 0, 0.06);
    }
  }

  tbody {
    tr {
      border-bottom: solid 1px rgba(0, 0, 0, 0.03);
      border-left: solid 1px rgba(0, 0, 0, 0.06);
      border-right: solid 1px rgba(0, 0, 0, 0.06);

      //&:last-child {
      //  border-bottom: none;
      //}
    }
  }

  tfoot {
    font-weight: bold;
  }

  th {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.75rem;
    text-align: left;
  }

  th,
  td {
    vertical-align: middle;
    padding: 0 1rem;
    height: 56px;
  }

  p {
    line-height: 1.7;
    padding: 0.75rem 0;
  }
}

.no-table-hover {
  tr:hover {
    color: inherit;
    background: transparent;
    cursor: auto;
  }
}

.page-section {
  padding-bottom: 4rem;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 5rem, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.xn-fadein-up {
  animation: fadeInUp 0.5s ease both;
}

.xn-delay-2 {
  animation-delay: 0.2s;
}

.xn-delay-3 {
  animation-delay: 0.3s;
}

.xn-delay-4 {
  animation-delay: 0.4s;
}

.xn-delay-5 {
  animation-delay: 0.5s;
}

.xn-delay-6 {
  animation-delay: 0.6s;
}

.page-inner-container {
  margin: 0 auto;
  width: 85%;

  @media (max-width: 766px) {
    width: 100%;
  }
}

.u-txt-bold {
  font-weight: bold;
}

.disable-scroll {
  overflow: hidden;
}

.u-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.is-table-header {
    button {
      top: -14px;
      position: relative;
    }
  }
}

button.u-profile-photo {
  border: none;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}
.user-display-icon{
  color: #192879;
  display: flex;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background-color: #e5e8fc;

}

.user_initials{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 100%;
}
.user-profile-photo {
  position: absolute;
  height: 36px;
  z-index: 5;
  width: 36px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  display: block;
  background-repeat: no-repeat;

  &.active {
    background-color: #78ad57;
  }

  &.medium {
    height: 56px;
    width: 56px;
  }
}

.u-profile-photo {
  height: 36px;
  width: 36px;
  background: $primary;
  border-radius: 100%;
  // background-image: url(../src/assets/images/user-avatar.png) !important;
  background-size: cover;
  background-position: center;
  display: block;
  background-repeat: no-repeat;

  &.active {
    background-color: #78ad57;
  }

  &.medium {
    height: 56px;
    width: 56px;
  }
}

.actions-button {
  .mat-icon {
    height: 0.75rem;
    width: 0.75rem;
    font-size: 1.125rem;
    margin-right: 0.85rem;
    position: relative;
    top: -4px;
  }
}

.u-mr-12 {
  margin-right: 12px;
}
.reset-button{
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 120px !important;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  transform: scale(0.5);

  &.bkg-blue {
    background-color: $primary;
  }

  &.fill-container {
    width: 100%;
    height: 100%;
  }

  &.fill-wh {
    transform: none;
  }

  & mat-spinner {
    margin: -130px auto 0 auto;
  }
}

.u-profile {
  display: flex;
  align-items: center;

  .u-profile-photo {
    margin-right: 0.9rem;
  }
}

.u-flex {
  display: flex;
}

.cdk-overlay-container {
  z-index: 10000;
}

.image-button {
  border: none;
  background: none;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity 0.3s;
  //border: solid 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  img {
    width: 100%;
    height: 128px;
    object-fit: cover;
  }

  &:hover {
    opacity: 0.75;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale3d(0, 0, 0);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes slideIn {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

button {
  outline: none;
}

.u-ptb-16 {
  padding: 16px 0;
}

.u-ptb-24 {
  padding: 24px 0;
}

.u-pb-16 {
  padding-bottom: 16px;
}

.u-block {
  display: block;
}

.rejection-form,
.request-alert {
  background-color: #fff;
  padding: 3rem;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 48px;

  textarea {
    width: 100%;
  }

  .mdc-text-field {
    width: 100%;
  }
}

.u-txt-small {
  font-size: 12px;
  opacity: 0.8;
}

.u-block {
  display: block;
}

.u-ptb-8 {
  padding: 8px 0;
}

.u-pb-4 {
  padding-bottom: 4px;
}

strong {
  font-weight: bold;
}

.edit-user-form {
  background-color: #fff;

  padding: 3rem;
  border-radius: 5px;

  .fields {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 50%;
      padding-right: 1rem;
    }
  }

  .fields_PIN {
    display: grid;
    grid-template-columns: 1fr 5fr;
    column-gap: 10px;

    // > div {
    //   width: 100%;
    //   padding-right: 1rem;
    // }
    span{
      font-weight: 800;
    }
  }

  .actions {
    padding-top: 2.5rem;
  }

  .actions_PIN {
    padding-top: 2.5rem;
    .active_user{
      color: #A4A3A3;
      font-weight: 500;
    }
  }

  .mdc-text-field {
    width: 100%;
  }
}

.u-pt-72 {
  padding-top: 4.5rem;
}

.u-pt-48 {
  padding-top: 3rem;
}

.overlay-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 2550, 0.75);
  z-index: 10;
}

.xn-input-file {
  position: relative;
  top: 3px;

  &:hover {
    .file-label {
      border-width: 2px;
      border-color: #000;
    }
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .control-label {
    position: absolute;
    transform: scale3d(0.75, 0.75, 0.75) translate3d(1rem, -0.75rem, 0);
    z-index: 1;
    background-color: white;
    font-weight: 500;
    pointer-events: none;
    letter-spacing: -0.2px;
    padding: 0 0.5rem;
    transform-origin: 0 0;
    color: #3f51b5;
  }

  .file-label {
    border-style: solid;
    border-color: #e1e2e1;
    border-width: 1px;
    padding: 1.125rem 1rem;
    height: 3.75rem;
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    font-weight: 500;
    display: block;
    cursor: pointer;
    overflow: hidden;
  }

  .icon {
    position: absolute;
    top: 1.25rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    pointer-events: none;
    z-index: 1;
    background-image: url("../src/assets/icons/folder.svg");
  }
}

.mat-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.mat-checkbox-wrap {
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  width: 50%;
}

.u-fill-w {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 5px !important;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}


.z-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info, .success, .warning, .error, .validation {
  border: 0 solid;
  margin: 10px 0;
  padding: 15px 10px 15px 50px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.info {
  color: #00529B;
  background-color: #BDE5F8;
  background-image: url('../src/assets/icons/info_2.png');
}

.success {
  color: #4F8A10;
  background-color: #DFF2BF;
  background-image: url('../src/assets/icons/success.png');
}

.warning {
  color: #9F6000;
  background-color: #FEEFB3;
  background-image: url('../src/assets/icons/warning.png');
}

.error {
  color: #D8000C;
  background-color: #FFBABA;
  background-image: url('../src/assets/icons/error.png');
}

.validation {
  color: #D63301;
  background-color: #FFCCBA;
  background-image: url('../src/assets/icons/info.png');
}

.uppercase {
  text-transform: uppercase;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 1em !important;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #3f51b5 !important;
  color: #fff;
  font-size: 1em;
}

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #6e78813b !important;
  color: #3f51b5 !important;
  font-size: 1em;
}

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 5000 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas:
        "top-start top top-end"
        "center-start center center-end"
        "bottom-start bottom-center bottom-end";
  grid-template-rows: minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto);
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  height: 100%;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color .1s;
  -webkit-overflow-scrolling: touch;
}

.u-fadeInY {
  animation: fadeInY 0.35s ease both;
}

.u-fs {
  &-10 {
    font-size: 0.625rem;
  }

  &-12 {
    font-size: 0.75rem;
  }

  &-13 {
    font-size: 0.8125rem;
  }

  &-14 {
    font-size: 0.875rem;
  }

  &-15 {
    font-size: 0.9375rem;
  }

  &-16 {
    font-size: 1rem;
  }


}


.mdc-form-field-appearance-legacy .mdc-form-field-underline {
  background-color: rgba(0, 0, 0, .42) !important;
  display: none !important;
}


.create-advisor-form {
  background-color: #fff;

  padding: 3rem;
  border-radius: 5px;

  .fields {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 50%;
      padding-right: 1rem;
    }
  }

  .actions {
    padding-top: 2.5rem;
  }

  .mdc-text-field {
    width: 100%;
  }
}


.create-note-form {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;

  h1{
    padding: 10px;
    color: #FFFFFF;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background:#4050b5;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
  form{
    padding: 1em;
  }
  .fields {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 50%;
    }
  }

  .actions {
    padding-top: 2.5rem;
  }

  .mdc-form-field {
    width: 100%;
  }
}



.edit-advisor-form {
  background-color: #fff;

  padding: 3rem;
  border-radius: 5px;

  .fields {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 50%;
      padding-right: 1rem;
    }
  }

  .actions {
    padding-top: 2.5rem;
  }

  .mdc-form-field {
    width: 100%;
  }
}

.mat-tab-label-container {
  display: flex !important;
  flex-grow: 1 !important;
  overflow: hidden !important;
  z-index: 1 !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.mat-select-panel {
  max-height: 300px !important;
}


.mat-menu-content:not(:empty) {
  padding-top: 2px!important;
  padding-bottom: 2px!important;
}

.mdc-form-field-appearance-fill .mdc-form-field-infix{
  padding: 0.12em 0.12em!important;
}

.mdc-form-field-appearance-fill .mdc-form-field-flex {
  border-radius: 4px 4px 0 0!important;
  padding: 0.12em 0.75em 0 0.75em!important;
}

.mdc-form-field-appearance-fill .mdc-form-field-infix {
  padding: 0.25em 0.12em !important;
}

.mdc-form-field-type-mat-date-range-input .mdc-form-field-infix {
  width: 180px!important;
}


panel:not([class*=mat-elevation-z]) {
  box-shadow: none!important;
}

.xn-mat-form-field-wrapper {
  position: relative!important;
  width: 100%!important;
}

.dropdown-icon_font-size {
  font-size: 20px;
  margin-right: -13px;
}


h6,  h5,  h4,  h3,  h2, h1, {
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-weight: bold;
  line-height: 1;
  //  font-family: "Playfair Display";
  font-size: 2.25rem;
}

.mat-card-image:first-child {
  margin-top: -15px!important;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mat-tab-label {
  height: 45px;
  padding: 0;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 10 !important;
  min-width: 160px;
  text-align: center;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  position: relative;
}
.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 2px!important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.card-header:first-child {
  padding-bottom: 0px!important;
  padding-top: 19px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: var(--phoenix-card-inner-border-radius) var(--phoenix-card-inner-border-radius) 0 0;
}

.border-bottom {
  border-bottom:  1px solid #cbd0dd !important;;
}
.bg-soft {
  background-color: #f5f7fa!important;
}

.mat-elevation-z1 {
  box-shadow: none!important;
}

.mat-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 7px!important;
}

.mat-radius {
  border-radius: 7px!important;
}
mat-paginator {
  border-top: 1px solid #ccccccb5!important;
  border-bottom: 1px solid #ccccccb5!important;
  border-left: solid 1px rgba(0, 0, 0, 0.06);
  border-right: solid 1px rgba(0, 0, 0, 0.06);
}

.mat-table {
  border-top-left-radius: 7px!important;
  border-top-right-radius: 7px!important;
}
.mat-paginator-container {
  display: flex;
  align-items: center;
  justify-content: space-between!important;
  padding: 0 21px!important;
  flex-wrap: wrap-reverse;
  width: 100%;
}

.page-title-sm{
  font-weight: bold;
  letter-spacing: -0.5px;
  font-size: 0.8rem;
  padding-bottom: 1rem;
}

.xn-grey{
  color: #7b7b7b;
}
.xn5 {
  margin-top: -5px;
}

.u-text {
  font-size: 1rem;
  letter-spacing: -.4px;
  //font-weight: 600;
}
.x-text {
  font-family: "DM Sans",sans-serif;
}

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder!important;
  white-space: nowrap;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4050b4!important;
  --bs-btn-border-color: #4050b4!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4050b4!important;
  --bs-btn-hover-border-color: #4050b4!important;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4050b4!important;
  --bs-btn-active-border-color: #4050b4!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4050b4!important;
  --bs-btn-disabled-border-color: #4050b4!important;
}

.xn-n11 {
  margin-bottom: -10px!important;
}

.mat-tab-header{
  // border-bottom: 1px solid red !important;
}


//.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
//  background-color: #cccccc!important;
//  height: 100px!important;
//  width: 160px!important;
//}



//.dropdown-item {
//  display: block;
//  width: 100%;
//  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
//  clear: both;
//  font-weight: 400;
//  color: var(--bs-dropdown-link-color);
//  text-align: inherit;
//  text-decoration: none;
//  white-space: nowrap;
//  background-color: transparent;
//  border: 0;
//  border-radius: var(--bs-dropdown-item-border-radius,0);
//}


.aum-absolute{
  font-size: 11px;
  color: green;
  display: flex;
  align-items: center;
  .span{
    font-size: 11px !important;
    color: green !important;
  }
  .percent-aum{
    margin-left: 5px;
  }
  .aum-cash{
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
  .aum-icon{
    font-size: 12px !important;
    height: 13px !important;
    width: 15px !important;
  }
}
.aum-absolute-negative{
  font-size: 11px;
  color: #D8392B;
  display: flex;
  align-items: center;
  .span{
    font-size: 11px !important;
    color: #D8392B !important;
  }
  .percent-aum{
    margin-left: 5px;
  }
  .aum-cash{
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
  .aum-icon{
    font-size: 12px !important;
    height: 13px !important;
    width: 15px !important;
  }
}
.aum-table{
  font-weight: 500;
}

.xn-text-label{
  font-size: 14px;
  padding-right: 5px;
  color: rgb(99, 99, 99);
}
.z-index-assigned{
  z-index: 10000;
  border: 1px solid red;
}

.notes-content{
  background: #D7DAF2;
  width: 100%;
  height: 100%;
  h1{
    padding: 10px;
    color: #FFFFFF;
    background:#4050b5;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
  p{
    padding: 10px;
    color: #575757;
    font-size: 13px;
  }
  &:hover{
    h1{
      color: #ecf5fe;
    }
    background: #ecf5fe;}

}

.notes-post{
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span{
    color: #6c727f;
    font-size: 12px;
  }
  .posted-on{

  }

}
.notes-body{
  height: 110px;
  overflow-y: auto;
}

.note-by{
  display: flex;
  flex-direction: row;
  align-items: center;
  span{
    color: #6c727f;
    font-size: 12px;
  }
}

.notes-comments{
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  span{
    color: #6c727f;
    font-size: 12px;
  }
  .note-icon{
    color: #6c727f;
    font-size: 12px;
    height: 12px;
    width: 18px;
  }
}



.note-p{
color: #57595d;
font-size: 14px;
margin-bottom: 10px;
}
.seperator-line{
  border-bottom: 0.5px solid rgb(174, 174, 174);
  width: 100%;
  margin-bottom: 20px;
}

.comment-activity{
  h5{
    color: #6c727f;
    font-size: 15px;
    margin-bottom: 20px;
  }
}


.text-caption{
  font-size: 12px;
  font-weight: bold;
  color: #989292;
}

.w-204px {
  width: 204px!important;
}





.user-name-label{
  margin-left: 10px;
  color: #467cf7;
}

.mat-mdc-paginator-container {
  display: flex;
  align-items: center;
  justify-content: space-between!important;
  padding: 0 8px;
  flex-wrap: wrap-reverse;
  width: 100%;
}

.mat-mdc-form-field-infix {
  min-height: 46px!important;
}

.mat-mdc-form-field-flex {
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  width: 100%;
  height: 46px!important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 22px!important;
}

.mat-mdc-select-arrow-wrapper {
  height: 24px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  padding-bottom: 10px!important;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 1px;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 83%!important;
  pointer-events: none;
}




.mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 0.75;
  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  text-align: left;
  width: 100%;
}

.mdc-line-ripple::after {
  transform: scaleX(0);
  opacity: 0;
  z-index: 2;
}

.mdc-line-ripple::after {
  border-bottom-width: 2px;
}

.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: none!important;
  content: "";
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white!important;
}

// Loader
.loader {
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  background-image: url("~assets/icons/spinner.svg");
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
  animation: spin 0.4s linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  // background-color: #00e2c1;
  // border-radius: 4rem;
  z-index: 1000;
  vertical-align: bottom;

  transition:
      opacity 0.5s,
      visibility 0.5s;

  &.dark {
      background-image: url("~assets/icons/spinner-dark.svg");
  }

  &.is-hidden {
      visibility: hidden;
      opacity: 0;
  }

  &.u-relative,
  &.relative {
      position: relative;
      display: block;
  }
}

.loader-0 {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("~assets/icons/spinner.svg");
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
  animation: spin 0.4s linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  // background-color: #00e2c1;
  // border-radius: 4rem;
  z-index: 1000;
  vertical-align: bottom;

  transition:
    opacity 0.5s,
    visibility 0.5s;

  &.dark {
    background-image: url("~assets/icons/spinner-dark.svg");
  }

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.u-relative,
  &.relative {
    position: relative;
    display: block;
  }
}

.fill-preloader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: -6px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.9);
  animation: fadeIn 0.3s ease both;

  &.full-h {
      height: 100vh;
  }

  &.no-fadein {
      animation: none;
  }

  &.dark-bkg {
      background-color: #111822;
  }

  .loader {
      position: relative;
  }

  .message {
      width: 25%;
      margin: 0 auto;
      text-align: center;
  }

  &.wizard {
      height: calc(100vh - 8rem);
      background-color: #fff;
  }

  @media (max-width: 766px) {
      .message {
          width: 90%;
      }
  }
}

@keyframes spin {
  0% {
      transform: rotateZ(0);
  }

  100% {
      transform: rotateZ(360deg);
  }
}

.fill-preloader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: -6px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.9);
  animation: fadeIn 0.3s ease both;

  &.full-h {
      height: 100vh;
  }

  &.no-fadein {
      animation: none;
  }

  &.dark-bkg {
      background-color: #111822;
  }

  .loader {
      position: relative;
  }

  .message {
      width: 25%;
      margin: 0 auto;
      text-align: center;
  }

  &.wizard {
      height: calc(100vh - 8rem);
      background-color: #fff;
  }

  @media (max-width: 766px) {
      .message {
          width: 90%;
      }
  }
}

/* text-confirm */
.text-confirm{
  font-size: 13px;
  color: grey;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  text-align: center !important;
}

.text-country{
  font-size: 13px;
  color: grey;
  padding: 2px;
  text-align: center !important;
}
.center-title{
  text-align: center !important;
  font-size: 16px;
  font-weight: 700;
}
.center-header-txt{
  font-size: 16px;
  font-weight: 500;
}

/* Center Modal */
.center-modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  top: 0;
  right: 0;
  z-index: 6000;
  background-color: rgba(0, 0, 0, 0.566);
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.center-content-box{
  box-shadow: 0 5px 15px rgba(92, 114, 182, 0.4);
  width: 400px !important;
  height: 230px !important;
  background: #fff;
  border-radius: 10px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-header{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content:center;
  width: 100%;
  background: #F5F5F5;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: #000!important;
  font-size: 13px !important;
}



@media (min-width: 1200px) {
  .nm-top75 {
    margin-top: -75px!important;
  }

  .filter-reset-btn {
    margin-top: 104px;
  }
  .nt103 {
    margin-top: -103px;
  }
  .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    width: 100px!important;
  }

  .mat-mdc-form-field-infix {
    flex: auto;
    min-width: 0;
    width: 134px!important;
    position: relative;
    box-sizing: border-box;
  }
}
@media (min-width: 992px) {
  .nm-top75 {
    margin-top: -75px!important;
  }

  .filter-reset-btn {
    margin-top: 104px;
  }
  .nt103 {
    margin-top: -103px;
  }
  .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    width: 100px!important;
  }

  .mat-mdc-form-field-infix {
    flex: auto;
    min-width: 0;
    width: 134px!important;
    position: relative;
    box-sizing: border-box;
  }
}
@media (min-width: 768px) {
  .nm-top75 {
    margin-top: -75px!important;
  }

  .filter-reset-btn {
    margin-top: 104px;
  }
  .nt103 {
    margin-top: -103px;
  }
  .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    width: 100px!important;
  }

  .mat-mdc-form-field-infix {
    flex: auto;
    min-width: 0;
    width: 134px!important;
    position: relative;
    box-sizing: border-box;
  }
}
@media (min-width: 576px) {
  .nm-top75 {
    margin-top: -75px!important;
  }

  .filter-reset-btn {
    margin-top: 104px;
  }
 .nt103 {
    margin-top: -103px;
  }
  .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    width: 100px!important;
  }

  .mat-mdc-form-field-infix {
    flex: auto;
    min-width: 0;
    width: 134px!important;
    position: relative;
    box-sizing: border-box;
  }
}


th, thead {
  font-weight: bolder !important;
  font-size: 12px !important;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: normal;
  pointer-events: all;
  //font-weight: 600!important;
  font-size: 11.6px !important;
  will-change: auto;
}

.cdk-overlay-pane {
  position: absolute;
  width: 245px;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}


//
//.lds-ellipsis,
//.lds-ellipsis div {
//  box-sizing: border-box;
//}
//.lds-ellipsis {
//  display: inline-block;
//  position: relative;
//  width: 80px;
//  height: 80px;
//}
//.lds-ellipsis div {
//  position: absolute;
//  top: 33.33333px;
//  width: 13.33333px;
//  height: 13.33333px;
//  border-radius: 50%;
//  background: currentColor;
//  animation-timing-function: cubic-bezier(0, 1, 1, 0);
//}
//.lds-ellipsis div:nth-child(1) {
//  left: 8px;
//  animation: lds-ellipsis1 0.6s infinite;
//}
//.lds-ellipsis div:nth-child(2) {
//  left: 8px;
//  animation: lds-ellipsis2 0.6s infinite;
//}
//.lds-ellipsis div:nth-child(3) {
//  left: 32px;
//  animation: lds-ellipsis2 0.6s infinite;
//}
//.lds-ellipsis div:nth-child(4) {
//  left: 56px;
//  animation: lds-ellipsis3 0.6s infinite;
//}
//@keyframes lds-ellipsis1 {
//  0% {
//    transform: scale(0);
//  }
//  100% {
//    transform: scale(1);
//  }
//}
//@keyframes lds-ellipsis3 {
//  0% {
//    transform: scale(1);
//  }
//  100% {
//    transform: scale(0);
//  }
//}
//@keyframes lds-ellipsis2 {
//  0% {
//    transform: translate(0, 0);
//  }
//  100% {
//    transform: translate(24px, 0);
//  }
//}



.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 2px solid #ff4080;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff4080 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nm-topx{
  //margin-top: -5100px!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
}

.deposits{
  color: #2a8c23;
}

.failed-transactions{
  color: #D8392B;
}


.mat-mdc-card {
  border-radius: var(--mdc-elevated-card-container-shape);
  background-color: var(--mdc-elevated-card-container-color);
  border-width: 0;
  border-style: solid;
  border-color: var(--mdc-elevated-card-container-color);
  box-shadow: none!important;
}


